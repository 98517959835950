export default {
  in: 'In',
  out: 'Out',
  login: 'Login',
  lastName: 'Last Name',
  firstName: 'First Name',
  invitationCode: 'Confirmation Number',
  checkin: 'Check In',
  checkout: 'Check Out',
  nights: 'Nights',
  guests: 'Guest',
  search: 'Search',
  accommodation: 'Accommodation',
  bookings: 'My Booking',
  customerSupport: 'Customer Support',
  china: 'Tel',
  overseas: 'Email',
  wechat: 'Wechat',
  signOut: 'Sign Out',
  hotelList: 'Hotel List',
  showMore: 'Show More',
  showDetail: 'Details',
  hotelDetail: 'Hotel Details',
  description: 'Description',
  roomType: 'Room Type',
  facilities: 'Facilities',
  reserve: 'Reserve',
  cancellationPolicy: 'Cancellation Policies',
  createReservation: 'Booking',
  yourInformation: 'Your Information',
  mobileNumber: 'Mobile Number',
  yourMobileNumber: 'Your Mobile Number',
  email: 'Email',
  yourEmail: 'Your email',
  yourFirstName: 'Your First Name',
  yourLastName: 'Your Last Name',
  gender: 'Gender',
  idType: 'ID Type',
  idNumber: 'ID Number',
  invitationCode1: 'Invitation Code',
  yourIdNumber: 'Your Card Info',
  yourInvitationCode: 'Your Invitation Code',
  remark: 'Remark',
  reservationRemark: 'We will deliver your request to the hotel and the specific arrangement will be in compliance with the hotel',
  total: 'Total',
  detail: 'Detail',
  bookNow: 'Book Now',
  payment: 'Payment',
  totalAmount: 'Total Amount',
  bookingDetails: 'Booking Details',
  payNow: 'Pay Now',
  back: 'Back',
  offlinePayment: 'Offline Payment',
  bookingInformation: 'Booking Information',
  offlinePayment2: 'OFFLINE PAYMENT',
  companyName: 'Company',
  companyBank: 'Bank',
  companyAccount: 'Account',
  submit: 'Submit',
  'file size limit is {limit} KB': 'Upload file size limit is {limit} KB',
  bookingSuccess: 'Payment Completed. If the reservation is successful, you will receive a confirmation letter sent by email.',
  successMessage: 'Please make sure you fill in the correct E-mail address, or the booking will be cancelled.',
  emailMessage: 'Please confirm the E-mail address or the confirmation letter will not be sent.',
  bookingList: 'Booking List',
  bookingNumber: 'Booking Number',
  signOutConfirm: 'Log out this web?',
  sorry: 'Sorry',
  restaurant: 'Restaurant',
  laundry: 'Laundry',
  'lobby-bar': 'Lobby Bar',
  gym: 'Gym',
  'room-wifi': 'Room WIFI',
  'swimming-pool': 'Swimming Pool',
  'non-smoking-floor': 'Non Smoking Floor',
  'meeting-room': 'Meeting Room',
  'butler-service': 'Butler Service',
  'parking-lot': 'Parking Lot',
  'for-disabled': 'For Disabled',
  'pickup-dropoff': 'Pick up & Drop off',
  Male: 'Male',
  Female: 'Female',
  readAndAccept: 'Read and accept',
  cancellationPolicies: 'Cancellation Policies',
  'reservationStatus.': 'No Reservation',
  reservationStatus: {
    Unpaid: 'Due to pay',
    Cancelled: 'Cancelled',
    Reserved: 'Reserved',
    'Checked In': 'Checked In',
    'Checked Out': 'Checked Out',
    'No Show': 'No Show'
  },
  'confirm to cancel this booking': 'Confirm to cancel this booking',
  'no booking': 'No Booking',
  invoice: 'Invoice',
  'all rooms sold out': 'All rooms sold out',
  createInvoice: 'Add Invoice',
  invoiceTitle: 'Invoice Title',
  invoiceType: 'Invoice Type',
  pleaseSelectInvoiceType: 'Please select Invoice Type',
  'hotel expense': 'Hotel Expense',
  'hotel expense(hotel)': 'Hotel Expense(Hotel)',
  service: 'Platform',
  'invoice provided by platform': 'Platform accommodation invoice',
  invoiceName: 'Company Name',
  invoiceTaxId: 'Tax ID',
  invoiceAddress: 'Address',
  invoiceTelephone: 'Telephone',
  invoiceBank: 'Bank',
  invoiceAccount: 'Account',
  selectInvoiceType: 'Select Invoice Type',
  pleaseInputInvoiceTitle: 'Please input Company Name',
  pleaseInputTaxId: 'Please input Tax ID',
  pleaseInputAddress: 'Please input company address',
  pleaseInputTelephone: 'Please input company telephone',
  pleaseInputBank: 'Please input company bank',
  pleaseInputBankAccount: 'Please input company bank account',
  mailInformation: 'Mail Information',
  mailName: 'Name',
  mailNumber: 'Mobile',
  mailAddress: 'Address',
  pleaseInputMailName: 'Name mail to',
  pleaseInputMailNumber: 'Mobile phone mail to',
  pleaseInputMailAddress: 'Address mail to',
  mailNote: 'The invoice will be sent out in 5-7 working days after checked out.',
  invoiceNote: 'Note: Accommodation invoice will be issued to you by the hotel when you leave the hotel. Please ask the hotel for it in time when you leave the hotel.',
  'VAT invoice Normal': 'VAT invoice (Normal)',
  'VAT invoice Special': 'VAT invoice (Special)',
  invoiceNameRequired: 'Invoice Title is required',
  invoiceTaxIdRequired: 'Invoice Tax ID is required',
  invoiceAddressRequired: 'Invoice Address is required',
  invoiceTelephoneRequired: 'Invoice Telephone is required',
  invoiceBankRequired: 'Invoice Bank is required',
  invoiceAccountRequired: 'Invoice Bank Account is required',
  bed: 'Bed',
  breakfast: 'Breakfast',
  price: 'Price',
  finalPrice: 'Final Price',
  bookingStatus: 'Booking Status',
  hotelName: 'Hotel Name',
  due: 'Due',
  details: 'Details',
  paymentMethod: 'Payment Method',
  payType: 'Pay Type',
  availableTime: 'Available Time',
  currency: 'Currency',
  company: 'Company',
  bank: 'Bank',
  account: 'Account',
  swiftCode: 'SWIFT CODE',
  bookingDate: 'Booking Date',
  'upload file only for JPG/JPEG/PNG , and it is limited to 5MB': 'upload file only for JPG/JPEG/PNG , and it is limited to 5MB',
  'contact number is required': 'Please input contact number',
  'email is required': 'Please input email',
  'last name is required': 'Please input Last Name',
  'first name is required': 'Please input First Name',
  'id number is required': 'Please input Id Number',
  'Please complete the payment in {day} day(s) {hour} hour(s) otherwise your booking will be cancel': 'Please complete the payment in {day} day(s) {hour} hour(s) otherwise your booking will be cancel',
  accompany: 'Sharer',
  accompanyNames: 'Sharer Names',
  paymentRemark: 'Please fill in your payment name and account in order to fasten your payment process',
  hotelConfirmNumber: 'Hotel Confirm Number',
  arrivalTime: 'Check in Time',
  departureTime: 'Check out time',
  'arrival time is required': 'Please select Arrival time',
  'departure time is required': 'Please select Departure time',
  'web title': 'Boao Forum For Asia Hotel Reservation Service',
  'login notify': 'Sorry to inform you that your name or the invitation code is not correct, please try again or contact the customer service.',
  'cancel notify': 'If you need to update or cancel your reservation, please contact the customer service.',
  'customer tel': 'Customer service Tel',
  'customer email': 'Customer service Email',
  'for more information': 'For more information, please contact us.',
  'footer customer tel': 'Tel',
  day: 'Day',
  hour: 'Hour',
  minute: 'Minute',
  second: 'Second',
  reservationNightsMessage: 'Booking requires at least two consecutive nights.',
  'do you need invoice': 'Do you need invoice ?',
  tips: 'Tips',
  'Open mobile wechat to scan payment': 'Open mobile wechat to scan',
  'Open zhifubao to scan payment': 'Open mobile aplipay to scan',
  'Please do not close the current page during payment': 'Please do not close the current page during payment',
  'Please do not pay repeatedly': 'Please do not pay repeatedly',
  'Online payment': 'Online Payment',
  close: 'Close',
  modify: 'Update/Cancel',
  'payment method not selected': 'Payment method not selected.',
  'payment notice': 'Notice: Once payment is selected, it cannot be changed.'
}
