import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { getLanguage } from '@/lang'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: getLanguage(),
    token: getToken()
  },
  mutations: {
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language', language)
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    }
  },
  actions: {
    setLanguage ({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setToken ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    resetToken ({ commit }) {
      commit('SET_TOKEN', '')
      removeToken()
      router.push('/')
    }
  },
  getters: {
    language: state => {
      return state.language
    }
  },
  modules: {
  }
})
