import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)

dayjs.tz.setDefault('Asia/Shanghai')
// dayjs.tz.setDefault('America/New_York')

export default dayjs
