// import Vue from 'vue'
import axios from 'axios'
import { Notify } from 'vant'
import store from '@/store'
import { getHeader } from '@/utils'

// create an axios instance
const service = axios.create({
  baseURL: '/mobile',
  'Content-Type': 'application/json;charset=utf-8',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    Object.assign(config.headers, getHeader())
    return config
  },
  error => {
    // do something with request error
    console.log('request:interceptors', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.errCode !== 0) {
      Notify(res.errMsg || res.message || 'Error')

      // // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.errCode === 50008 || res.errCode === 50012 || res.errCode === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload()
      //     })
      //   })
      // }
      return Promise.reject(new Error(res.errMsg || res.message || 'Error'))
    } else {
      return Promise.resolve(res)
    }
  },
  error => {
    if (error.response.status === 403) {
      store.dispatch('resetToken')
    } else {
      Notify(error.message)
    }
    console.error('response:interceptors:error', error) // for debug
    // return Promise.reject(error)
  }
)

export default service
