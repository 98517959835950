<template>
  <div class="footer">
    <div>
      <div>
        <img src="@/assets/boao.png" width="180px" />
      </div>
      <div>
        <div style="font-size: 30px;">博鳌亚洲论坛酒店预订系统</div>
        <div style="font-size: 14px;">BOAO FORUM FOR ASIA HOTEL RESERVATION SERVICE</div>
      </div>
    </div>
    <div>
      <div>
        <img src="@/assets/dongfangmeijing.png" />
      </div>
      <div style="font-size: 20px;">
        <div>{{ $t('for more information') }}</div>
        <div style="font-size: 14px;">
          <span>{{ $t('footer customer tel') }}: +86-400-098-2996</span>
          <span style="padding-left: 10px;">Email: hotel@bjome.com.cn</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #b2b2b2;
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif;
  padding-bottom: 30px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
</style>
